import React, { useEffect, useRef } from 'react';

// Simplified Driving Mode Handler - controls the map view in driving mode
const DrivingModeHandler = ({ isDrivingMode, followedVehicle, viewState, setViewState }) => {
  const updateIntervalRef = useRef(null);
  const lastLogTimeRef = useRef(0);
  const drivingModeRef = useRef(isDrivingMode);
  const vehicleRef = useRef(followedVehicle);
  
  // Update refs when props change to avoid dependency in the effect
  useEffect(() => {
    drivingModeRef.current = isDrivingMode;
    vehicleRef.current = followedVehicle;
    
    // Handle enabling/disabling of driving mode
    if (isDrivingMode && !updateIntervalRef.current && followedVehicle) {
      console.log("DrivingModeHandler: Enabling driving mode for vehicle:", followedVehicle.displayName);
      setupTracking();
    } else if (!isDrivingMode && updateIntervalRef.current) {
      console.log("DrivingModeHandler: Disabling driving mode tracking");
      clearInterval(updateIntervalRef.current);
      updateIntervalRef.current = null;
    }
  }, [isDrivingMode, followedVehicle]);
  
  // Function to set up the tracking interval
  const setupTracking = () => {
    // Function to update the map position
    const updateMapPosition = () => {
      const currentVehicle = vehicleRef.current;
      const isDrivingModeActive = drivingModeRef.current;
      
      if (!isDrivingModeActive || !currentVehicle || !currentVehicle.latitude || !currentVehicle.longitude) return;
      
      const now = Date.now();
      if (now - lastLogTimeRef.current > 1000) {
        console.log("DrivingModeHandler: Updating map position with vehicle heading:", currentVehicle.heading);
        lastLogTimeRef.current = now;
      }
      
      // Simply update the view state to match the vehicle's position and heading
      setViewState({
        longitude: currentVehicle.longitude,
        latitude: currentVehicle.latitude,
        bearing: currentVehicle.heading || 0,
        zoom: viewState?.zoom || 16, // Maintain current zoom level
        pitch: 0, // Keep a flat 2D view 
        transitionDuration: 300,
      });
    };
    
    // Run once immediately
    updateMapPosition();
    
    // Set up interval for continuous updates
    updateIntervalRef.current = setInterval(updateMapPosition, 300);
  };
  
  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (updateIntervalRef.current) {
        console.log("DrivingModeHandler: Cleaning up interval on unmount");
        clearInterval(updateIntervalRef.current);
        updateIntervalRef.current = null;
      }
    };
  }, []);
  
  return null; // This component doesn't render anything visible
};

export default DrivingModeHandler; 