import React from 'react';
import { useMap } from 'react-map-gl/mapbox';

const MapboxZoomControls = () => {
  const { current: map } = useMap();
  
  const controlsContainerStyle = {
    position: 'fixed',
    left: '15px',
    bottom: '170px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    zIndex: 1000
  };
  
  const buttonStyle = {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: 'rgba(25, 25, 25, 0.85)',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
    border: '2px solid rgba(255, 255, 255, 0.2)',
    color: 'white',
    fontSize: '42px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.4)',
    transition: 'transform 0.2s, background-color 0.2s',
    padding: 0,
    lineHeight: 1,
    userSelect: 'none'
  };
  
  const handleZoomIn = () => {
    if (map) {
      const newZoom = Math.min(map.getZoom() + 1, 18);
      map.easeTo({
        zoom: newZoom,
        duration: 300
      });
    }
  };
  
  const handleZoomOut = () => {
    if (map) {
      const newZoom = Math.max(map.getZoom() - 1, 15);
      map.easeTo({
        zoom: newZoom,
        duration: 300
      });
    }
  };
  
  return (
    <div style={controlsContainerStyle}>
      <button 
        style={{
          ...buttonStyle,
          transform: 'scale(1.02)',
        }} 
        onClick={handleZoomIn}
        title="Zoom In"
        className="mobile-button zoom-button"
      >
        +
      </button>
      <button 
        style={buttonStyle} 
        onClick={handleZoomOut}
        title="Zoom Out"
        className="mobile-button zoom-button"
      >
        −
      </button>
      <style>{`
        .zoom-button:active {
          transform: scale(0.95);
          background-color: rgba(41, 117, 247, 0.9) !important;
        }
        
        @media (max-width: 480px) {
          .zoom-button {
            width: 70px !important;
            height: 70px !important;
            font-size: 38px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default MapboxZoomControls; 