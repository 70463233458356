import React, { useEffect, useRef } from 'react';

// Start the ContinuousVehicleTracker component
const ContinuousVehicleTracker = ({ followedVehicle, isDrivingMode, setViewState, currentViewState }) => {
  const lastUpdateRef = useRef(null);
  const intervalRef = useRef(null);
  const vehicleRef = useRef(followedVehicle);

  // Update ref when followedVehicle changes to avoid dependency in the effect
  useEffect(() => {
    vehicleRef.current = followedVehicle;
  }, [followedVehicle]);

  // Keep track of vehicle updates and center map - only run this once
  useEffect(() => {
    console.log("ContinuousVehicleTracker: Setting up tracking system");
    
    // Function to update the map position when the vehicle moves
    const updateMapPosition = () => {
      const currentVehicle = vehicleRef.current;
      if (!currentVehicle || !currentVehicle.latitude || !currentVehicle.longitude) {
        return;
      }
      
      const now = Date.now();
      // Only log every second to avoid console spam
      if (!lastUpdateRef.current || now - lastUpdateRef.current > 1000) {
        console.log(
          "Centering map on vehicle:", 
          currentVehicle.displayName, 
          "at", 
          currentVehicle.latitude.toFixed(6), 
          currentVehicle.longitude.toFixed(6),
          "Current map position:",
          currentViewState ? 
            `${currentViewState.latitude.toFixed(6)}, ${currentViewState.longitude.toFixed(6)}` : 
            "unknown"
        );
        lastUpdateRef.current = now;
      }
      
      setViewState(prev => {
        // Don't update if we're already centered (prevents jitter)
        if (Math.abs(prev.latitude - currentVehicle.latitude) < 0.00001 && 
            Math.abs(prev.longitude - currentVehicle.longitude) < 0.00001) {
          return prev;
        }
        
        return {
          ...prev,
          longitude: currentVehicle.longitude,
          latitude: currentVehicle.latitude,
          // In driving mode, only update the bearing to match vehicle heading
          ...(isDrivingMode ? { 
            bearing: currentVehicle.heading || 0,
            pitch: 0 // Keep a flat 2D view in driving mode
          } : {})
        };
      });
    };
    
    // Set up interval to continuously track the vehicle if not already set up
    if (!intervalRef.current) {
      // Run once immediately
      updateMapPosition();
      
      // Then set up the interval
      intervalRef.current = setInterval(updateMapPosition, 100); // Update 10 times per second
      console.log("ContinuousVehicleTracker: Started tracking interval");
    }
    
    // Clean up only when component unmounts, not on every re-render
    return () => {
      if (intervalRef.current) {
        console.log("ContinuousVehicleTracker: Cleaning up interval on unmount");
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  // Empty dependency array means this runs once on mount and cleans up on unmount
  }, []); 
  
  return null;
};

export default ContinuousVehicleTracker; 