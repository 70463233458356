import React, { useState, useEffect, useRef, useCallback } from 'react';
import Map, {
  Marker,
  Popup,
  Source,
  Layer
} from 'react-map-gl/mapbox';
import 'mapbox-gl/dist/mapbox-gl.css';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import '../../App.css';

// Import components - will be updated as we migrate
import Header from '../mobile/Header';
import StatusBar from '../mobile/StatusBar';
import MarkerNameModal from '../mobile/modals/MarkerNameModal';
import MapboxZoomControls from './MapboxZoomControls';
import MapboxVehicleSearch from './MapboxVehicleSearch'; // Import Mapbox-specific vehicle search
import ApiControl from './ApiControl'; // Renamed from OverpassAPIControl

// Import refactored components
import VehicleMarker from './VehicleMarker';
import TempMarker from './TempMarker';
import ContinuousVehicleTracker from './ContinuousVehicleTracker';
import DrivingModeHandler from './DrivingModeHandler';

// Import utilities
import { getVehicleIconUrl, haversineDistance } from './utils/vehicleUtils';
import geocodingService from './utils/reverseGeocodingService'; // Import the geocoding service

// Import constants
import { MAPBOX_ACCESS_TOKEN, MAP_STYLES } from './constants/mapStyles';

// Add custom styles for the MapboxMobileMap component
// This ensures the vehicle search displays correctly
const mapboxMobileStyles = `
  .map-vehicle-search-wrapper .search-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: auto;
  }
  
  .map-vehicle-search-wrapper .search-results {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .driving-mode-indicator {
    position: fixed;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(41, 117, 247, 0.9);
    color: white;
    padding: 8px 16px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 14px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);
  }
  
  .search-loading {
    text-align: center;
    padding: 10px;
    font-style: italic;
    color: #666;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.9;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Add padding to the bottom of the map to account for the status bar */
  .mapboxgl-map {
    padding-bottom: 150px !important;
  }
  
  /* Adjust position of map controls to account for status bar */
  .mapboxgl-ctrl-bottom-right {
    bottom: 160px !important;
  }
  
  .mapboxgl-ctrl-bottom-left {
    bottom: 160px !important;
  }
`;

// Main MapBox Mobile Map Component
const MapboxMobileMap = ({ user, toggleMobileMode }) => {
  // State from the original MobileMap, adapted for MapBox
  const [vehicles, setVehicles] = useState([]);
  const [followedVehicle, setFollowedVehicle] = useState(null);
  const [darkMode, setDarkMode] = useState(true); // Start in dark mode
  const [markers, setMarkers] = useState([]);
  const [isMarkingMode, setIsMarkingMode] = useState(false);
  const [showNameModal, setShowNameModal] = useState(false);
  const [tempMarkerPosition, setTempMarkerPosition] = useState(null);
  const [isDrivingMode, setIsDrivingMode] = useState(false);
  const [expandedNotifications, setExpandedNotifications] = useState({});
  const [showVehicleSearch, setShowVehicleSearch] = useState(true); // Control visibility of vehicle search
  
  // Add state for ambulance vehicles
  const [ambulanceVehicles, setAmbulanceVehicles] = useState([]);
  
  // Add state for incidents and drawn items
  const [incidents, setIncidents] = useState([]);
  const [activeIncidents, setActiveIncidents] = useState([]);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [drawnItems, setDrawnItems] = useState([]);
  const [showIncidents, setShowIncidents] = useState(true); // Start with incidents visible
  
  // Add state for geocoded location
  const [geocodedLocation, setGeocodedLocation] = useState(null);
  const [isGeocoding, setIsGeocoding] = useState(false);
  
  // Add state for tracking vehicle movement statistics
  const [movementStats, setMovementStats] = useState({
    totalVehicles: 0,
    movingVehicles: 0,
    lastUpdate: null,
    initialPositions: {}, // Store initial positions of vehicles
  });
  
  // Add ref for the SSE connection
  const vehicleSourceRef = useRef(null);
  const mapRef = useRef(null);
  const viewStateRef = useRef(null);
  
  // Add a ref for the style element
  const styleRef = useRef(null);
  
  // Inject custom styles for the mobile map
  useEffect(() => {
    // Create a style element if it doesn't exist
    if (!styleRef.current) {
      const styleElement = document.createElement('style');
      styleElement.textContent = mapboxMobileStyles;
      document.head.appendChild(styleElement);
      styleRef.current = styleElement;
    }
    
    // Clean up on unmount
    return () => {
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
        styleRef.current = null;
      }
    };
  }, []);
  
  // MapBox specific state with White Plains, NY coordinates
  const [viewState, setViewState] = useState({
    longitude: -73.7629, // White Plains, NY coordinates
    latitude: 41.0340,
    zoom: 14,
    bearing: 0,
    pitch: 0,
    padding: { top: 0, bottom: 0, left: 0, right: 0 }
  });
  
  // Determine current map style based on dark mode
  const [useMapboxStyles, setUseMapboxStyles] = useState(true);
  
  const getCurrentMapStyle = useCallback(() => {
    if (!useMapboxStyles) {
      return darkMode ? MAP_STYLES.fallbackDark : MAP_STYLES.fallbackLight;
    }
    return darkMode ? MAP_STYLES.navigationNight : MAP_STYLES.navigation;
  }, [darkMode, useMapboxStyles]);
  
  // Handle style loading errors (switches to fallback if Mapbox styles fail)
  const handleStyleLoadError = useCallback((error) => {
    console.error("Map style load error:", error);
    // If we get an authorization error, switch to fallback styles
    if (error.status === 401 || error.status === 403) {
      console.log("Switching to fallback map styles due to authorization error");
      setUseMapboxStyles(false);
    }
  }, []);
  
  // Toggle function for dark mode
  const toggleDarkMode = () => {
    setDarkMode(prev => !prev);
  };
  
  // Function to handle when a vehicle is selected for following
  const handleVehicleFollow = useCallback(async (vehicle) => {
    console.log("Vehicle selected for following:", vehicle);
    
    // Minimum zoom level for driving mode
    const MIN_ZOOM_LEVEL = 15;
    
    // Hide the vehicle search after selection
    setShowVehicleSearch(false);
    
    // Enable driving mode automatically when a vehicle is selected
    const enableDrivingMode = () => {
      // Set driving mode state
      setIsDrivingMode(true);
      
      // Disable pan and rotate controls for driving mode
      if (mapRef.current) {
        const map = mapRef.current.getMap();
        map.dragPan.disable();
        map.dragRotate.disable();
        
        // Ensure zoom controls remain enabled
        if (map.scrollZoom.isEnabled() === false) {
          map.scrollZoom.enable();
        }
        if (map.doubleClickZoom.isEnabled() === false) {
          map.doubleClickZoom.enable();
        }
        if (map.touchZoomRotate.isEnabled() === false) {
          // Enable zoom but disable rotate
          map.touchZoomRotate.enable();
          map.touchZoomRotate.disableRotation();
        }
        
        // Limit the minimum zoom level to prevent zooming out too far
        map.setMinZoom(MIN_ZOOM_LEVEL);
        
        // Add a CSS class to the map container for driving mode styling
        map.getContainer().classList.add('driving-mode');
      }
      
      // Notify the user
      toast.info(`Now in driving mode with ${followedVehicle?.displayName || vehicle?.displayName || 'vehicle'}`, {
        position: "top-center",
        autoClose: 2000
      });
    };
    
    // If we just have the displayName (string)
    if (typeof vehicle === 'string') {
      // Fetch the complete vehicle data - this will need to be updated to use 
      // the fetchVehicleLocation function from your geocodingUtils
      try {
        // Get user email from props
        const userEmail = user?.userEmail;
        
        if (!userEmail) {
          console.error("Cannot follow vehicle: No user email available");
          setFollowedVehicle({ displayName: vehicle });
          return;
        }
        
        // Use the environment variable base URL if available
        const baseUrl = process.env.REACT_APP_API_URL || '';
        
        // Use the proper API endpoint
        const url = `${baseUrl}/api/vehicles/${vehicle}`;
        console.log(`Fetching vehicle data from: ${url}`);
        
        const response = await fetch(url, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'X-User-Email': userEmail
          }
        });
        
        if (response.ok) {
          const vehicleData = await response.json();
          console.log("Successfully fetched vehicle data:", vehicleData);
          setFollowedVehicle(vehicleData);
          
          // Preserve the current zoom level if it exists
          const currentZoom = viewState.zoom;
          // Determine appropriate zoom level - must be at least MIN_ZOOM_LEVEL
          const zoomLevel = Math.max(currentZoom, MIN_ZOOM_LEVEL);
          
          console.log(`Setting view for vehicle follow. Using zoom level: ${zoomLevel} (current: ${currentZoom}, min: ${MIN_ZOOM_LEVEL})`);
          
          // Immediately center the map on the followed vehicle and match its heading for driving mode
          setViewState(prev => ({
            ...prev,
            longitude: vehicleData.longitude,
            latitude: vehicleData.latitude,
            zoom: zoomLevel, // Use determined zoom level with minimum enforced
            bearing: vehicleData.heading || 0, // Always set bearing as we're enabling driving mode
            pitch: 0,
            transitionDuration: 500
          }));
          
          // Enable driving mode after a brief delay to ensure map is positioned
          setTimeout(() => {
            enableDrivingMode();
          }, 200);
          
          // Force update after a brief delay to ensure map moves
          setTimeout(() => {
            if (vehicleData && vehicleData.latitude && vehicleData.longitude) {
              console.log("Forcing map update after delay");
              setViewState(prev => ({
                ...prev,
                longitude: vehicleData.longitude,
                latitude: vehicleData.latitude,
                bearing: vehicleData.heading || 0, // Always set bearing for driving mode
                // Keep zoom at the same level (won't be below minimum)
              }));
            }
          }, 300);
        } else {
          console.error(`Failed to fetch vehicle data: ${response.status}`);
          // Fallback to just the display name if we can't get the data
          setFollowedVehicle({ displayName: vehicle });
        }
      } catch (error) {
        console.error("Error fetching vehicle data:", error);
        setFollowedVehicle({ displayName: vehicle });
      }
    } 
    // If we have a vehicle object
    else {
      console.log("Setting followed vehicle with data:", vehicle);
      setFollowedVehicle(vehicle);
      
      // Preserve the current zoom level if it exists
      const currentZoom = viewState.zoom;
      // Determine appropriate zoom level - must be at least MIN_ZOOM_LEVEL
      const zoomLevel = Math.max(currentZoom, MIN_ZOOM_LEVEL);
      
      console.log(`Setting view for vehicle follow. Using zoom level: ${zoomLevel} (current: ${currentZoom}, min: ${MIN_ZOOM_LEVEL})`);
      
      // Immediately center the map on the followed vehicle and match its heading for driving mode
      setViewState(prev => ({
        ...prev,
        longitude: vehicle.longitude,
        latitude: vehicle.latitude,
        zoom: zoomLevel, // Use determined zoom level with minimum enforced
        bearing: vehicle.heading || 0, // Always set bearing as we're enabling driving mode
        pitch: 0,
        transitionDuration: 500
      }));
      
      // Enable driving mode after a brief delay to ensure map is positioned
      setTimeout(() => {
        enableDrivingMode();
      }, 200);
      
      // Force update after a brief delay to ensure map moves
      setTimeout(() => {
        if (vehicle && vehicle.latitude && vehicle.longitude) {
          console.log("Forcing map update after delay");
          setViewState(prev => ({
            ...prev,
            longitude: vehicle.longitude,
            latitude: vehicle.latitude,
            bearing: vehicle.heading || 0, // Always set bearing for driving mode
            // Keep zoom at the same level (won't be below minimum)
          }));
        }
      }, 300);
    }
  }, [user, toast, viewState.zoom]); // Add viewState.zoom as a dependency
  
  // Function to handle marker creation
  const handleCreateMarker = () => {
    setIsMarkingMode(true);
  };
  
  // Function to handle map clicks for placing markers
  const handleMapClick = useCallback((event) => {
    if (isMarkingMode) {
      setTempMarkerPosition([event.lngLat.lng, event.lngLat.lat]);
      setShowNameModal(true);
      setIsMarkingMode(false);
    }
  }, [isMarkingMode]);
  
  // Function to save a marker
  const saveMarker = async (markerName) => {
    if (!tempMarkerPosition || !markerName.trim()) return;
    
    try {
      // Get user data from URL or state
      const currentUser = user;
      const userEmail = user?.userEmail;
      
      if (!userEmail) {
        console.error("Cannot save marker: No user email available");
        return;
      }
      
      // Create a new marker object
      const newMarker = {
        id: Date.now().toString(), // Simple unique ID
        longitude: tempMarkerPosition[0],
        latitude: tempMarkerPosition[1],
        name: markerName, // Use the name provided by the user
        createdBy: currentUser.userName || "Unknown User",
        createdAt: new Date().toISOString()
      };
      
      // Add to local state first for immediate feedback
      setMarkers(prev => [...prev, newMarker]);
      
      // Use the environment variable base URL if available
      const baseUrl = process.env.REACT_APP_API_URL || '';
      
      // Use the proper API endpoint
      const url = `${baseUrl}/api/tempmarkers`;
      
      // Send the marker to the server to broadcast to all users
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-User-Email': userEmail
        },
        credentials: 'include',
        body: JSON.stringify(newMarker)
      });
      
      if (!response.ok) {
        console.error('Failed to save temp marker on server:', await response.text());
        // Keep the marker locally even if server sync fails
      }
      
      // Reset temporary state
      setTempMarkerPosition(null);
      setShowNameModal(false);
      
    } catch (error) {
      console.error('Error saving temp marker:', error);
      // Keep the marker locally even if there's an error
    }
  };
  
  // Function to delete a marker
  const deleteMarker = async (markerId) => {
    // Remove from local state immediately for responsive UI
    setMarkers(prev => prev.filter(marker => marker.id !== markerId));
    
    try {
      const userEmail = user?.userEmail;
      
      if (!userEmail) {
        console.error("Cannot delete marker: No user email available");
        return;
      }
      
      // Use the environment variable base URL if available
      const baseUrl = process.env.REACT_APP_API_URL || '';
      
      // Use the proper API endpoint
      const url = `${baseUrl}/api/tempmarkers/${markerId}`;
      
      // Send delete request to server
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'X-User-Email': userEmail
        },
        credentials: 'include'
      });
      
      if (!response.ok) {
        console.error('Failed to delete temp marker on server:', await response.text());
      }
    } catch (error) {
      console.error('Error deleting temp marker:', error);
    }
  };
  
  // Fetch markers when component mounts
  useEffect(() => {
    const fetchMarkers = async () => {
      try {
        // Get user email
        const userEmail = user?.userEmail;
        
        if (!userEmail) {
          console.error("Cannot fetch markers: No user email available");
          return;
        }
        
        // Use the environment variable base URL if available
        const baseUrl = process.env.REACT_APP_API_URL || '';
        
        // Use the API endpoint for tempmarkers with properly authenticated request
        const url = `${baseUrl}/api/tempmarkers`;
        console.log(`Fetching markers from: ${url}`);
        
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'X-User-Email': userEmail
          },
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        setMarkers(data);
      } catch (error) {
        console.error('Error fetching temp markers:', error);
      }
    };
    
    if (user?.userEmail) {
      fetchMarkers();
    } else {
      console.log('No user data, skipping marker fetch');
    }
    
  }, [user]);
  
  // Add useEffect to track vehicle movements
  useEffect(() => {
    if (vehicles.length === 0) return;
    
    const currentTime = new Date();
    let movingCount = 0;
    let newInitialPositions = {...movementStats.initialPositions};
    
    // Loop through all vehicles to check if they've moved
    vehicles.forEach(vehicle => {
      // Store initial position if not already stored
      if (!newInitialPositions[vehicle.displayName || vehicle.id]) {
        newInitialPositions[vehicle.displayName || vehicle.id] = {
          latitude: vehicle.latitude,
          longitude: vehicle.longitude,
          timestamp: currentTime
        };
      } else {
        // Check if vehicle has moved from initial position
        const initialPos = newInitialPositions[vehicle.displayName || vehicle.id];
        const movedSignificantly = 
          Math.abs(initialPos.latitude - vehicle.latitude) > 0.00001 || 
          Math.abs(initialPos.longitude - vehicle.longitude) > 0.00001;
          
        if (movedSignificantly) {
          movingCount++;
        }
      }
    });
    
    // Update movement stats
    setMovementStats(prev => ({
      ...prev,
      totalVehicles: vehicles.length,
      movingVehicles: movingCount,
      lastUpdate: currentTime,
      initialPositions: newInitialPositions
    }));
    
  }, [vehicles]); // Only update when the vehicles array changes
  
  // Keep a reference to the current viewState for debugging
  useEffect(() => {
    viewStateRef.current = viewState;
  }, [viewState]);
  
  // Custom view state handler that respects following
  const handleViewStateChange = useCallback((evt) => {
    // Skip updating viewState from map interactions if we're following a vehicle
    // This prevents the map from "fighting" with the vehicle tracking
    if (followedVehicle && !evt.originalEvent) {
      setViewState(evt.viewState);
    } else if (!followedVehicle) {
      setViewState(evt.viewState);
    }
  }, [followedVehicle]);
  
  // Update the SSE effect for vehicle data fetching
  useEffect(() => {
    // Cleanup function for SSE connection
    const cleanupSSE = () => {
      if (vehicleSourceRef.current) {
        console.log("Closing SSE connection");
        vehicleSourceRef.current.close();
        vehicleSourceRef.current = null;
      }
    };
    
    const setupSSEConnection = () => {
      try {
        // Skip if we already have a connection
        if (vehicleSourceRef.current) {
          console.log("SSE connection already exists, skipping setup");
          return;
        }
        
        // Get user email from props or storage
        const userEmail = user?.userEmail;
        
        if (!userEmail) {
          console.error("Cannot connect to SSE: No user email available");
          return;
        }
        
        // Clean up any existing connection
        cleanupSSE();
        
        // Use the environment variable base URL if available
        const baseUrl = process.env.REACT_APP_API_URL || '';
        // Connect to the SSE endpoint with mobileMode flag
        const url = `${baseUrl}/sse/vehicles?userEmail=${encodeURIComponent(userEmail)}&mobileMode=true`;
        console.log(`Connecting to SSE for vehicles: ${url}`);
        
        vehicleSourceRef.current = new EventSource(url, { withCredentials: true });
        
        vehicleSourceRef.current.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            if (data && data.features) {
              console.log(`Received SSE vehicle update with ${data.features.length} features`);
              
              // Extract vehicle data from GeoJSON format
              const vehicleData = data.features.map(feature => {
                // Extract coords from GeoJSON Point geometry
                const [longitude, latitude] = feature.geometry?.coordinates || [-73.8803, 41.0793];
                return {
                  ...feature.properties,
                  id: feature.id || feature.properties?.id || Math.random().toString(36).substring(2, 9),
                  longitude,
                  latitude,
                  // Try to extract heading from properties
                  heading: feature.properties?.heading || feature.properties?.direction || 0
                };
              });
              
              // Check for ambulance vehicles in the main stream
              const ambulanceVehicles = vehicleData.filter(v => {
                // Identify ambulances based on properties or display name
                const displayName = (v.displayName || '').toLowerCase();
                const type = (v.type || '').toLowerCase();
                
                return type.includes('ems') || 
                       type.includes('ambulance') || 
                       displayName.includes('ambulance') || 
                       displayName.includes('ems') || 
                       displayName.includes('medic') ||
                       displayName.includes('als') ||
                       displayName.includes('bls');
              });
              
              // If we found ambulances, set them in state
              if (ambulanceVehicles.length > 0) {
                console.log(`Found ${ambulanceVehicles.length} ambulances in main vehicle stream`);
                
                // Mark these as ambulances for styling
                const formattedAmbulances = ambulanceVehicles.map(v => ({
                  ...v,
                  isAmbulance: true
                }));
                
                setAmbulanceVehicles(formattedAmbulances);
              }
              
              // We got valid data, filter for police vehicles or use all if we have no police
              const policeVehicles = vehicleData.filter(v => {
                // Adapt this filter based on your data structure
                const type = (v.type || '').toLowerCase();
                return type.includes('police') || type.includes('sheriff');
              });
              
              // If we have police vehicles, use them, otherwise use all vehicles
              const vehiclesToUse = policeVehicles.length > 0 ? policeVehicles : vehicleData;
              
              setVehicles(vehiclesToUse);
              
              // If we're following a vehicle, update its data without recreating the SSE connection
              setFollowedVehicle(prev => {
                if (!prev) return prev;
                
                const updatedFollowedVehicle = vehicleData.find(v => 
                  v.displayName === prev.displayName
                );
                
                if (updatedFollowedVehicle) {
                  console.log("Updating followed vehicle data:", updatedFollowedVehicle);
                  return updatedFollowedVehicle;
                }
                
                return prev;
              });
            }
          } catch (error) {
            console.error("Error parsing SSE data:", error);
          }
        };
        
        vehicleSourceRef.current.onerror = (error) => {
          console.error("SSE connection error:", error);
          // Try to reconnect after a delay
          setTimeout(() => {
            if (vehicleSourceRef.current) {
              cleanupSSE();
              setupSSEConnection(); // Try to reconnect
            }
          }, 5000);
        };
      } catch (error) {
        console.error("Error setting up SSE connection:", error);
      }
    };
    
    // Set up SSE connection
    setupSSEConnection();
    
    // Clean up on unmount
    return cleanupSSE;
  // Only depend on user, not followedVehicle
  }, [user]);

  // Function to toggle driving mode
  const toggleDrivingMode = () => {
    // Minimum zoom level for driving mode
    const MIN_ZOOM_LEVEL = 15;
    
    // If enabling driving mode, make sure we follow a vehicle
    if (!isDrivingMode && !followedVehicle) {
      toast.info("Select a vehicle to follow first", {
        position: "top-center",
        autoClose: 3000
      });
      return;
    }
    
    // Log the vehicle heading and direction info
    if (followedVehicle) {
      console.log(`--- Driving Mode Toggle Info ---`);
      console.log(`Current vehicle heading: ${followedVehicle.heading || 0}°`);
      console.log(`Map bearing before toggle: ${viewState.bearing}°`);
      console.log(`Current zoom level: ${viewState.zoom}`);
      console.log(`Driving mode changing from ${isDrivingMode ? 'ON to OFF' : 'OFF to ON'}`);
    }
    
    // Toggle driving mode state
    setIsDrivingMode(prev => !prev);
    
    // When enabling driving mode, adjust view to match vehicle heading
    if (!isDrivingMode && followedVehicle) {
      // Determine appropriate zoom level - ensure it's at least MIN_ZOOM_LEVEL
      const newZoom = Math.max(viewState.zoom, MIN_ZOOM_LEVEL);
      
      // Apply map rotation to match vehicle heading
      setViewState({
        ...viewState,
        longitude: followedVehicle.longitude,
        latitude: followedVehicle.latitude,
        bearing: followedVehicle.heading || 0, // Set bearing to match vehicle heading
        pitch: 0, // Keep a flat view without pitch
        zoom: newZoom, // Enforce minimum zoom level
        transitionDuration: 500 // Smooth transition into driving mode
      });
      
      // In driving mode, only disable pan and rotate, but keep zoom enabled
      if (mapRef.current) {
        const map = mapRef.current.getMap();
        map.dragPan.disable(); // Disable panning
        map.dragRotate.disable(); // Disable rotation
        
        // Ensure zoom controls remain enabled
        if (map.scrollZoom.isEnabled() === false) {
          map.scrollZoom.enable();
        }
        if (map.doubleClickZoom.isEnabled() === false) {
          map.doubleClickZoom.enable();
        }
        if (map.touchZoomRotate.isEnabled() === false) {
          // Enable zoom but disable rotate
          map.touchZoomRotate.enable();
          map.touchZoomRotate.disableRotation();
        }
        
        // Limit the minimum zoom level to prevent zooming out too far
        map.setMinZoom(MIN_ZOOM_LEVEL);
        
        // Add a CSS class to the map container for driving mode styling
        map.getContainer().classList.add('driving-mode');
      }
      
      // Notify the user
      toast.info(`Now in driving mode with ${followedVehicle.displayName || 'vehicle'}`, {
        position: "top-center",
        autoClose: 2000
      });
      
    } else {
      // Reset view when exiting driving mode
      setViewState({
        ...viewState,
        pitch: 0,
        bearing: 0, // Reset bearing to north
        transitionDuration: 500 // Smooth transition out of driving mode
        // Note: No longer setting zoom, preserving user's current zoom level
      });
      
      // Re-enable all map interactions when exiting driving mode
      if (mapRef.current) {
        const map = mapRef.current.getMap();
        map.dragPan.enable();
        map.dragRotate.enable();
        
        // Reset the minimum zoom restriction when exiting driving mode
        map.setMinZoom(0); // Default minimum zoom
        
        // Remove the driving mode class
        map.getContainer().classList.remove('driving-mode');
      }
      
      // Notify the user
      toast.info('Exited driving mode', {
        position: "top-center",
        autoClose: 2000
      });
    }
  };
  
  // Function to handle showing the vehicle search dropdown
  const showVehicleSearchDropdown = () => {
    setShowVehicleSearch(true);
  };

  // Function to get address for the current location of the followed vehicle
  const updateLocationInfo = useCallback(async (vehicle) => {
    if (!vehicle || (!vehicle.latitude && vehicle.latitude !== 0) || (!vehicle.longitude && vehicle.longitude !== 0)) {
      console.log("No valid vehicle data for geocoding:", vehicle);
      return;
    }
    
    // Parse coordinates as numbers to ensure they're the right type
    // Handle potential string values from API
    const latitude = typeof vehicle.latitude === 'string' ? parseFloat(vehicle.latitude) : vehicle.latitude;
    const longitude = typeof vehicle.longitude === 'string' ? parseFloat(vehicle.longitude) : vehicle.longitude;
    const heading = typeof vehicle.heading === 'string' ? parseFloat(vehicle.heading || 0) : (vehicle.heading || 0);
    
    console.log("------------------------------");
    console.log("GEOCODING ATTEMPT:");
    console.log(`Vehicle ID: ${vehicle.id || 'N/A'}, Display Name: ${vehicle.displayName || 'N/A'}`);
    console.log(`Coordinates: lat=${latitude}, lon=${longitude}, heading=${heading}`);
    console.log(`Original types: lat=${typeof vehicle.latitude}, lon=${typeof vehicle.longitude}, heading=${typeof vehicle.heading}`);
    console.log("------------------------------");
    
    // Check if coordinates are valid numbers
    if (isNaN(latitude) || isNaN(longitude)) {
      console.error("Invalid coordinates detected:", vehicle.latitude, vehicle.longitude);
      return;
    }
    
    setIsGeocoding(true);
    try {
      // Pass vehicle heading to the geocoding service for better intersection lookup
      console.log("Calling geocodingService.getAddressForLocation with:", latitude, longitude, heading);
      const location = await geocodingService.getAddressForLocation(
        latitude,
        longitude,
        heading
      );
      
      console.log("Geocoding result:", location ? "SUCCESS" : "FAILED (null result)");
      if (location) {
        console.log("Geocoded address:", location.address);
        console.log("Street:", location.street);
        console.log("Locality:", location.locality);
        
        setGeocodedLocation(location);
        console.log("State updated with geocoded location");
      } else {
        console.warn("No location data returned from geocoding service");
      }
    } catch (error) {
      console.error('Error during geocoding:', error);
      toast.error('Error getting location information');
    } finally {
      setIsGeocoding(false);
    }
  }, []);
  
  // Update geocoding whenever followed vehicle position changes
  useEffect(() => {
    if (!followedVehicle || !followedVehicle.latitude || !followedVehicle.longitude) {
      return;
    }
    
    // Debounce geocoding requests to reduce API calls
    const timeoutId = setTimeout(() => {
      updateLocationInfo(followedVehicle);
    }, 500);
    
    return () => clearTimeout(timeoutId);
  }, [followedVehicle?.latitude, followedVehicle?.longitude, followedVehicle?.heading, updateLocationInfo]);

  // Add specific handler for vehicle updates from SSE events
  useEffect(() => {
    // Store reference to original handler
    let originalOnMessage = null;
    
    // Extract vehicle info from the SSE event handler
    const handleVehicleUpdate = (updatedVehicle) => {
      // Only process if we're following a vehicle and this is the one we're following
      if (!followedVehicle || !updatedVehicle) return;
      
      // Check if this is the vehicle we're following
      if (followedVehicle.displayName === updatedVehicle.displayName) {
        console.log("SSE update for followed vehicle detected:", updatedVehicle.displayName);
        
        // Check if coordinates have changed significantly to avoid unnecessary geocoding
        const hasSignificantMove = followedVehicle.latitude !== updatedVehicle.latitude || 
                                   followedVehicle.longitude !== updatedVehicle.longitude;
        
        if (hasSignificantMove) {
          console.log("Vehicle position changed, triggering geocoding update");
          updateLocationInfo(updatedVehicle);
        } else {
          console.log("Vehicle position unchanged, skipping geocoding");
        }
      }
    };
    
    // Set up event listener for SSE events
    const vehicleSource = vehicleSourceRef.current;
    if (vehicleSource) {
      // Save original handler
      originalOnMessage = vehicleSource.onmessage;
      
      vehicleSource.onmessage = (event) => {
        // Call the original handler first
        if (originalOnMessage) {
          originalOnMessage(event);
        }
        
        // Then handle our geocoding logic
        try {
          const data = JSON.parse(event.data);
          if (data && data.features && followedVehicle) {
            // Find our vehicle in the data
            const updatedVehicleData = data.features
              .map(feature => {
                const [longitude, latitude] = feature.geometry?.coordinates || [0, 0];
                return {
                  ...feature.properties,
                  id: feature.id || feature.properties?.id || '',
                  longitude,
                  latitude,
                  heading: feature.properties?.heading || feature.properties?.direction || 0
                };
              })
              .find(v => v.displayName === followedVehicle.displayName);
            
            if (updatedVehicleData) {
              handleVehicleUpdate(updatedVehicleData);
            }
          }
        } catch (error) {
          console.error("Error processing SSE data for geocoding:", error);
        }
      };
    }
    
    // Cleanup function
    return () => {
      if (vehicleSource && vehicleSource.onmessage && originalOnMessage) {
        // Restore original onmessage handler
        vehicleSource.onmessage = originalOnMessage;
      }
    };
  }, [followedVehicle, updateLocationInfo]);

  // Fetch incidents when component mounts
  useEffect(() => {
    const fetchIncidents = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_URL || '';
        const response = await fetch(`${baseUrl}/api/incidents`, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'X-User-Email': user?.userEmail
          }
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        setIncidents(data);
        
        // Filter active incidents
        const active = data.filter(incident => incident.active);
        setActiveIncidents(active);
        
        console.log(`Fetched ${data.length} incidents, ${active.length} active`);
        
        // Automatically select the most recent active incident if any exist and none are selected
        if (active.length > 0 && !selectedIncident) {
          // Sort by created_at date (most recent first) and select the first one
          const mostRecent = [...active].sort((a, b) => 
            new Date(b.created_at || 0) - new Date(a.created_at || 0)
          )[0];
          
          setSelectedIncident(mostRecent);
          
          // Fetch drawn items for this incident immediately
          fetchDrawnItemsForIncident(mostRecent.incident_id);
        }
        
        // Remove toast notification about active incidents - we're showing them automatically
      } catch (error) {
        console.error('Error fetching incidents:', error);
      }
    };
    
    // Create a function to fetch drawn items for a specific incident
    const fetchDrawnItemsForIncident = async (incidentId) => {
      try {
        const baseUrl = process.env.REACT_APP_API_URL || '';
        const response = await fetch(
          `${baseUrl}/api/drawn-items?incident_id=${incidentId}`,
          {
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'X-User-Email': user?.userEmail
            }
          }
        );
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        setDrawnItems(data.filter(item => item.active));
        console.log(`Fetched ${data.length} drawn items for incident ${incidentId}`);
      } catch (error) {
        console.error('Error fetching drawn items:', error);
      }
    };
    
    if (user?.userEmail) {
      fetchIncidents();
      // Set up polling for incidents every 30 seconds
      const intervalId = setInterval(fetchIncidents, 30000);
      return () => clearInterval(intervalId);
    }
  }, [user, selectedIncident]);
  
  // No need for separate fetchDrawnItems useEffect - we're combining the logic above

  // Handle incident click - update to focus on incident
  const handleIncidentClick = (incident) => {
    setSelectedIncident(incident);
    
    // Fetch drawn items for this incident specifically
    if (incident && incident.incident_id) {
      const fetchDrawnItemsForIncident = async (incidentId) => {
        try {
          const baseUrl = process.env.REACT_APP_API_URL || '';
          const response = await fetch(
            `${baseUrl}/api/drawn-items?incident_id=${incidentId}`,
            {
              credentials: 'include',
              headers: {
                'Accept': 'application/json',
                'X-User-Email': user?.userEmail
              }
            }
          );
          
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          
          const data = await response.json();
          setDrawnItems(data.filter(item => item.active));
          console.log(`Fetched ${data.length} drawn items for incident ${incidentId}`);
        } catch (error) {
          console.error('Error fetching drawn items for incident:', error);
        }
      };
      
      fetchDrawnItemsForIncident(incident.incident_id);
    }
    
    // Center map on incident
    setViewState(prev => ({
      ...prev,
      longitude: incident.location_long,
      latitude: incident.location_lat,
      zoom: 16,
      transitionDuration: 500
    }));
  };

  // Function to convert Leaflet circle to Mapbox circle
  const createCircleLayer = (incident) => {
    // Convert radius from miles to meters
    const radiusInMeters = incident.radius * 1609.34;
    
    // Create a GeoJSON source for the circle
    return {
      type: 'circle',
      paint: {
        'circle-radius': ['/', radiusInMeters, ['cos', ['*', ['get', 'lat'], 0.0174533]]],
        'circle-color': selectedIncident?.incident_id === incident.incident_id ? '#3388ff' : '#aad3df',
        'circle-opacity': selectedIncident?.incident_id === incident.incident_id ? 0.4 : 0.2,
        'circle-stroke-width': 1,
        'circle-stroke-color': selectedIncident?.incident_id === incident.incident_id ? '#3388ff' : '#aad3df',
      },
      filter: ['==', 'incident_id', incident.incident_id]
    };
  };

  return (
    <div style={{ 
      height: '100vh', 
      width: '100%', 
      position: 'relative',
      backgroundColor: darkMode ? '#121212' : '#ffffff'
    }}>
      <Map
        {...viewState}
        ref={mapRef}
        onMove={handleViewStateChange}
        style={{ 
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: '100vh'
        }}
        mapStyle={getCurrentMapStyle()}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        onClick={handleMapClick}
        dragRotate={isDrivingMode} // Only enable rotation in driving mode
        touchZoomRotate={true}
        attributionControl={false} // Hide default attribution
        logoPosition='bottom-left'
        id="mapbox-main"
        renderWorldCopies={true}
        trackResize={true}
        boxZoom={true}
        cooperativeGestures={false}
        doubleClickZoom={true}
        onError={(error) => {
          console.error("Map error:", error);
          // Non-critical errors like missing tiles shouldn't affect the map
          if (error.status === 404 && error.url && error.url.includes('mapbox-incidents')) {
            console.log("Ignoring non-critical 404 for incidents layer");
            return; // Non-critical error
          }
          
          // For critical errors, try switching to fallback styles
          if (error.status === 401 || error.status === 403) {
            setUseMapboxStyles(false);
          }
        }}
        onStyleLoadError={handleStyleLoadError}
        transformRequest={(url, resourceType) => {
          // Log resource requests to debug issues
          if (resourceType === 'Source' && !url.includes('maptiler')) {
            console.log(`Loading map resource: ${resourceType} - ${url}`);
          }
          return { url };
        }}
      >
        {/* Custom Zoom Controls */}
        <MapboxZoomControls />
        
        {/* Movement Statistics Display */}
        {vehicles.length > 0 && (
          <div className="movement-stats">
            <div className="stat">
              <span>Total Vehicles:</span>
              <span className="value">{movementStats.totalVehicles}</span>
            </div>
            <div className="stat">
              <span>Moving Vehicles:</span>
              <span className="value highlight">{movementStats.movingVehicles}</span>
            </div>
            <div className="stat">
              <span>Movement %:</span>
              <span className={`value ${movementStats.movingVehicles > 0 ? 'highlight' : 'static'}`}>
                {movementStats.totalVehicles > 0 
                  ? Math.round((movementStats.movingVehicles / movementStats.totalVehicles) * 100) 
                  : 0}%
              </span>
            </div>
            <div className="stat">
              <span>Last Update:</span>
              <span className="value">
                {movementStats.lastUpdate 
                  ? movementStats.lastUpdate.toLocaleTimeString() 
                  : 'N/A'}
              </span>
            </div>
          </div>
        )}
        
        {/* Vehicle Tracking System */}
        {followedVehicle && (
          <ContinuousVehicleTracker 
            key="vehicle-tracker"
            followedVehicle={followedVehicle}
            isDrivingMode={isDrivingMode}
            setViewState={setViewState}
            currentViewState={viewState}
          />
        )}
        
        {/* Driving Mode Handler */}
        <DrivingModeHandler
          key="driving-mode-handler"
          isDrivingMode={isDrivingMode}
          followedVehicle={followedVehicle}
          viewState={viewState}
          setViewState={setViewState}
        />
        
        {/* Render Active Incidents */}
        {showIncidents && activeIncidents.map(incident => {
          if (!incident.location_lat || !incident.location_long || !incident.radius) return null;
          
          // Create a GeoJSON feature collection for each incident
          const geojson = {
            type: 'FeatureCollection',
            features: [{
              type: 'Feature',
              properties: {
                incident_id: incident.incident_id,
                name: incident.name,
                lat: incident.location_lat  // Needed for radius calculation
              },
              geometry: {
                type: 'Point',
                coordinates: [incident.location_long, incident.location_lat]
              }
            }]
          };
          
          // Check if this is the currently selected incident
          const isSelected = selectedIncident?.incident_id === incident.incident_id;
          
          return (
            <React.Fragment key={`incident-${incident.incident_id}`}>
              <Source id={`incident-source-${incident.incident_id}`} type="geojson" data={geojson}>
                <Layer {...createCircleLayer(incident)} />
              </Source>
              
              {/* Incident Name Label - Always shown */}
              <Marker
                longitude={incident.location_long}
                latitude={incident.location_lat}
                anchor="center"
                onClick={() => handleIncidentClick(incident)}
              >
                <div style={{
                  backgroundColor: isSelected ? 'rgba(0, 123, 255, 0.9)' : 'rgba(73, 80, 87, 0.7)',
                  color: 'white',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                }}>
                  {incident.name}
                </div>
              </Marker>
            </React.Fragment>
          );
        })}
        
        {/* Render Drawn Items for Selected Incident - Now visible by default */}
        {selectedIncident && drawnItems.map(item => {
          if (!item.geojson || !item.geojson.geometry) return null;
          
          const { type, coordinates } = item.geojson.geometry;
          const { name, color, radius, markerType } = item.geojson.properties;
          
          // Circle drawn item (most common)
          if (type === 'Point' && markerType === 'Circle' && radius) {
            // Create a GeoJSON feature for the circle
            const circleGeojson = {
              type: 'FeatureCollection',
              features: [{
                type: 'Feature',
                properties: {
                  id: item.id,
                  name: name,
                  color: color || '#3388ff',
                  lat: coordinates[1]  // Needed for radius calculation
                },
                geometry: {
                  type: 'Point',
                  coordinates: [coordinates[0], coordinates[1]]
                }
              }]
            };
            
            // Create circle layer configuration
            const circleLayer = {
              id: `drawn-circle-${item.id}`,
              type: 'circle',
              paint: {
                'circle-radius': ['/', radius * 1609.34, ['cos', ['*', ['get', 'lat'], 0.0174533]]],
                'circle-color': color || '#3388ff',
                'circle-opacity': 0.2,
                'circle-stroke-width': 2,
                'circle-stroke-color': color || '#3388ff',
              }
            };
            
            return (
              <React.Fragment key={`drawn-item-${item.id}`}>
                <Source id={`drawn-source-${item.id}`} type="geojson" data={circleGeojson}>
                  <Layer {...circleLayer} />
                </Source>
                
                {/* Label for the circle - Always shown */}
                {name && (
                  <Marker
                    longitude={coordinates[0]}
                    latitude={coordinates[1]}
                    anchor="center"
                  >
                    <div style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      color: 'black',
                      padding: '2px 6px',
                      borderRadius: '3px',
                      fontSize: '11px',
                      fontWeight: 'bold',
                      boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                    }}>
                      {name}
                    </div>
                  </Marker>
                )}
              </React.Fragment>
            );
          }
          
          // LineString drawn item (lines, routes)
          else if (type === 'LineString') {
            const lineGeojson = {
              type: 'FeatureCollection',
              features: [{
                type: 'Feature',
                properties: {
                  id: item.id,
                  name: name,
                  color: color || '#3388ff'
                },
                geometry: {
                  type: 'LineString',
                  coordinates: coordinates
                }
              }]
            };
            
            const lineLayer = {
              id: `drawn-line-${item.id}`,
              type: 'line',
              paint: {
                'line-color': color || '#3388ff',
                'line-width': 3,
                'line-opacity': 0.8
              }
            };
            
            // For line items, show labels at both ends
            return (
              <React.Fragment key={`drawn-item-${item.id}`}>
                <Source id={`drawn-source-${item.id}`} type="geojson" data={lineGeojson}>
                  <Layer {...lineLayer} />
                </Source>
                
                {/* Show label for line if it has a name */}
                {name && (
                  // Place label at the midpoint of the line
                  <Marker
                    longitude={coordinates[Math.floor(coordinates.length / 2)][0]}
                    latitude={coordinates[Math.floor(coordinates.length / 2)][1]}
                    anchor="center"
                  >
                    <div style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      color: 'black',
                      padding: '2px 6px',
                      borderRadius: '3px',
                      fontSize: '11px',
                      fontWeight: 'bold',
                      boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                    }}>
                      {name}
                    </div>
                  </Marker>
                )}
              </React.Fragment>
            );
          }
          
          // Polygon drawn item (areas)
          else if (type === 'Polygon') {
            const polygonGeojson = {
              type: 'FeatureCollection',
              features: [{
                type: 'Feature',
                properties: {
                  id: item.id,
                  name: name,
                  color: color || '#3388ff'
                },
                geometry: {
                  type: 'Polygon',
                  coordinates: coordinates
                }
              }]
            };
            
            const polygonFillLayer = {
              id: `drawn-polygon-fill-${item.id}`,
              type: 'fill',
              paint: {
                'fill-color': color || '#3388ff',
                'fill-opacity': 0.2
              }
            };
            
            const polygonOutlineLayer = {
              id: `drawn-polygon-outline-${item.id}`,
              type: 'line',
              paint: {
                'line-color': color || '#3388ff',
                'line-width': 2,
                'line-opacity': 0.8
              }
            };
            
            // Calculate center of polygon for label placement
            const findCentroid = (coords) => {
              if (!coords || !coords.length || !coords[0] || !coords[0].length) {
                return [0, 0];
              }
              
              const points = coords[0]; // First ring of polygon
              let sumX = 0;
              let sumY = 0;
              
              for (let i = 0; i < points.length; i++) {
                sumX += points[i][0];
                sumY += points[i][1];
              }
              
              return [sumX / points.length, sumY / points.length];
            };
            
            const centroid = findCentroid(coordinates);
            
            return (
              <React.Fragment key={`drawn-item-${item.id}`}>
                <Source id={`drawn-source-${item.id}`} type="geojson" data={polygonGeojson}>
                  <Layer {...polygonFillLayer} />
                  <Layer {...polygonOutlineLayer} />
                </Source>
                
                {/* Show label at center of polygon */}
                {name && (
                  <Marker
                    longitude={centroid[0]}
                    latitude={centroid[1]}
                    anchor="center"
                  >
                    <div style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      color: 'black',
                      padding: '2px 6px',
                      borderRadius: '3px',
                      fontSize: '11px',
                      fontWeight: 'bold',
                      boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                    }}>
                      {name}
                    </div>
                  </Marker>
                )}
              </React.Fragment>
            );
          }
          
          return null;
        })}
        
        {/* Render Vehicles */}
        {vehicles.map(vehicle => (
          <VehicleMarker
            key={vehicle.displayName || vehicle.id}
            vehicle={vehicle}
            followedVehicle={followedVehicle}
            handleVehicleFollow={handleVehicleFollow}
            isDrivingMode={isDrivingMode}
          />
        ))}
        
        {/* Render Ambulance Vehicles */}
        {ambulanceVehicles.map(vehicle => (
          <VehicleMarker
            key={vehicle.displayName || vehicle.id}
            vehicle={vehicle}
            followedVehicle={followedVehicle}
            handleVehicleFollow={handleVehicleFollow}
            isAmbulance={true}
            isDrivingMode={isDrivingMode}
          />
        ))}
        
        {/* Render temporary marker while placing */}
        {tempMarkerPosition && (
          <Marker
            longitude={tempMarkerPosition[0]}
            latitude={tempMarkerPosition[1]}
            anchor="bottom"
            color="#FF0000"
          >
            📍
          </Marker>
        )}
        
        {/* Render all saved markers */}
        {markers.map(marker => (
          <TempMarker
            key={marker.id}
            marker={marker}
            onDelete={deleteMarker}
            user={user}
          />
        ))}
        
        {/* Add attribution control */}
        <div style={{
          position: 'absolute',
          bottom: '5px',
          right: '5px',
          fontSize: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          padding: '2px 5px',
          borderRadius: '3px',
          zIndex: 1
        }}>
          © <a href="https://www.mapbox.com/about/maps/">Mapbox</a> | <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>
        </div>
      </Map>
      
      {/* Frosted Glass Overlay when vehicle search is open */}
      {showVehicleSearch && (
        <div 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 900,
            backgroundColor: darkMode ? 'rgba(10, 10, 10, 0.7)' : 'rgba(255, 255, 255, 0.7)',
            backdropFilter: 'blur(8px) saturate(120%)',
            WebkitBackdropFilter: 'blur(8px) saturate(120%)',
            pointerEvents: 'auto', // Block clicks to force interaction with the vehicle search
            borderRadius: 0
          }}
          onClick={(e) => {
            // Prevent clicks from reaching the map
            e.preventDefault();
            e.stopPropagation();
            
            // Optional: Add a subtle visual or toast notification to guide the user
            toast.info("Please select a vehicle to continue", {
              position: "top-center", 
              autoClose: 2000,
              hideProgressBar: true
            });
          }}
        />
      )}
      
      {/* Add a selected incident info panel - more compact version */}
      {selectedIncident && (
        <div 
          style={{
            position: 'absolute',
            bottom: '170px',
            right: '10px',
            backgroundColor: darkMode ? 'rgba(30, 30, 30, 0.75)' : 'rgba(255, 255, 255, 0.75)',
            color: darkMode ? 'white' : 'black',
            padding: '8px',
            borderRadius: '8px',
            zIndex: 900,
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)',
            maxWidth: '200px',
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(5px)',
            border: darkMode 
              ? '1px solid rgba(255, 255, 255, 0.1)' 
              : '1px solid rgba(0, 0, 0, 0.1)',
            fontSize: '11px'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '4px' }}>
            <div style={{ margin: 0, fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {selectedIncident.name}
            </div>
            <button 
              onClick={() => setSelectedIncident(null)}
              style={{
                background: 'none', 
                border: 'none',
                fontSize: '14px',
                cursor: 'pointer',
                color: darkMode ? 'white' : 'black',
                padding: '0',
                marginLeft: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '16px',
                height: '16px'
              }}
            >
              ✕
            </button>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginTop: '2px', alignItems: 'center' }}>
            <span style={{ 
              backgroundColor: 'rgba(41, 117, 247, 0.2)', 
              color: darkMode ? 'white' : '#333',
              padding: '2px 4px',
              borderRadius: '3px',
              fontSize: '10px'
            }}>
              {selectedIncident.type_incident}
            </span>
            <span style={{ 
              backgroundColor: 'rgba(0, 0, 0, 0.2)', 
              color: darkMode ? 'white' : '#333',
              padding: '2px 4px',
              borderRadius: '3px',
              fontSize: '10px'
            }}>
              {drawnItems.length} item{drawnItems.length !== 1 ? 's' : ''}
            </span>
          </div>
        </div>
      )}
        
      {/* Header with user info only, dark mode toggle is in top bar */}
      <Header 
        user={user} 
        darkMode={darkMode} 
        hideToggle={true} // Hide the dark mode toggle since it's now in the top bar
      />
      
      {/* Top controls bar - contains action buttons that span across the top */}
      <div style={{
        position: 'fixed',
        top: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
        padding: '0 15px',
        backgroundColor: darkMode ? 'rgba(20, 20, 20, 0.6)' : 'rgba(255, 255, 255, 0.6)',
        backdropFilter: 'blur(5px)',
        WebkitBackdropFilter: 'blur(5px)',
        boxShadow: '0 1px 8px rgba(0, 0, 0, 0.1)',
        height: '60px',
        width: '100%',
        maxWidth: '100%'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          maxWidth: '100%'
        }}>
          {/* Driving mode indicator - integrated in the header */}
          {isDrivingMode && (
            <div style={{
              backgroundColor: 'rgba(41, 117, 247, 0.9)',
              color: 'white',
              padding: '6px 12px',
              borderRadius: '15px',
              fontWeight: 'bold',
              fontSize: '12px',
              marginRight: '8px',
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '150px'
            }}>
              <span style={{ marginRight: '4px' }}>🚗</span>
              <span className="driving-mode-text">
                {followedVehicle?.displayName || 'Vehicle'}
              </span>
            </div>
          )}

          {/* Controls in a single row */}
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '8px'
          }} className="mobile-button-container">
            {/* Dark Mode Toggle */}
            <button 
              className="mobile-button"
              style={{
                backgroundColor: darkMode ? 'rgba(41, 117, 247, 0.9)' : 'rgba(25, 25, 25, 0.7)',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                color: 'white',
                border: '1px solid rgba(255, 255, 255, 0.15)',
                borderRadius: '18px',
                padding: '5px 10px',
                fontSize: '11px',
                fontWeight: '500',
                cursor: 'pointer',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '30px',
                minWidth: '30px',
                whiteSpace: 'nowrap'
              }}
              onClick={toggleDarkMode}
            >
              <span style={{ marginRight: '3px', fontSize: '12px' }}>
                {darkMode ? '☀️' : '🌙'}
              </span>
              <span className="button-text">{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
            </button>
            
            {/* Incidents Toggle Button - Updated label */}
            <button 
              className="mobile-button"
              style={{
                backgroundColor: showIncidents ? 'rgba(41, 117, 247, 0.9)' : 'rgba(25, 25, 25, 0.7)',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                color: 'white',
                border: '1px solid rgba(255, 255, 255, 0.15)',
                borderRadius: '18px',
                padding: '5px 10px',
                fontSize: '11px',
                fontWeight: '500',
                cursor: 'pointer',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '30px',
                minWidth: '30px',
                whiteSpace: 'nowrap',
                position: 'relative'
              }}
              onClick={() => setShowIncidents(prev => !prev)}
            >
              <span style={{ marginRight: '3px', fontSize: '12px' }}>
                {showIncidents ? '👁️' : '👁️‍🗨️'}
              </span>
              <span className="button-text">
                {showIncidents ? 'Hide Incidents' : 'Show Incidents'}
              </span>
              {activeIncidents.length > 0 && !showIncidents && (
                <span style={{
                  position: 'absolute',
                  top: '-5px',
                  right: '-5px',
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '50%',
                  width: '18px',
                  height: '18px',
                  fontSize: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold'
                }}>
                  {activeIncidents.length}
                </span>
              )}
            </button>
            
            {/* Mark Location Button */}
            <button 
              className="mobile-button"
              style={{
                backgroundColor: isMarkingMode ? 'rgba(220, 53, 69, 0.8)' : 'rgba(25, 25, 25, 0.7)',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                color: 'white',
                border: '1px solid rgba(255, 255, 255, 0.15)',
                borderRadius: '18px',
                padding: '5px 10px',
                fontSize: '11px',
                fontWeight: '500',
                cursor: 'pointer',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '30px',
                minWidth: '30px',
                whiteSpace: 'nowrap'
              }}
              onClick={isMarkingMode ? () => setIsMarkingMode(false) : handleCreateMarker}
            >
              <span style={{ marginRight: '3px', fontSize: '12px' }}>
                {isMarkingMode ? '✕' : '📍'}
              </span>
              <span className="button-text">{isMarkingMode ? 'Cancel' : 'Mark Location'}</span>
            </button>
            
            {/* Vehicle search button */}
            {!showVehicleSearch && followedVehicle && (
              <button 
                className="mobile-button"
                style={{
                  backgroundColor: 'rgba(41, 117, 247, 0.9)',
                  backdropFilter: 'blur(10px)',
                  WebkitBackdropFilter: 'blur(10px)',
                  color: 'white',
                  border: '1px solid rgba(255, 255, 255, 0.15)',
                  borderRadius: '18px',
                  padding: '5px 10px',
                  fontSize: '11px',
                  fontWeight: '500',
                  cursor: 'pointer',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '30px',
                  minWidth: '30px',
                  whiteSpace: 'nowrap'
                }}
                onClick={showVehicleSearchDropdown}
              >
                <span style={{ marginRight: '3px', fontSize: '12px' }}>🔄</span>
                <span className="button-text">Change Vehicle</span>
              </button>
            )}
            
            {/* Driving Mode Button */}
            <button 
              className={`mobile-button ${isDrivingMode ? 'active exit-driving' : ''}`}
              style={{
                backgroundColor: isDrivingMode ? 
                  'rgba(52, 152, 219, 0.9)' : 'rgba(25, 25, 25, 0.7)',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                color: 'white',
                border: '1px solid rgba(255, 255, 255, 0.15)',
                borderRadius: '18px',
                padding: '5px 10px',
                fontSize: '11px',
                fontWeight: '500',
                cursor: 'pointer',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '30px',
                minWidth: '30px',
                whiteSpace: 'nowrap'
              }}
              onClick={toggleDrivingMode}
            >
              <span style={{ marginRight: '3px', fontSize: '12px' }}>🚗</span>
              <span className="button-text">{isDrivingMode ? 'Exit Driving' : 'Driving Mode'}</span>
            </button>
            
            {/* Desktop Mode Button */}
            <button 
              className="mobile-button"
              style={{
                backgroundColor: 'rgba(25, 25, 25, 0.7)',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                color: 'white',
                border: '1px solid rgba(255, 255, 255, 0.15)',
                borderRadius: '18px',
                padding: '5px 10px',
                fontSize: '11px',
                fontWeight: '500',
                cursor: 'pointer',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '30px',
                minWidth: '30px',
                whiteSpace: 'nowrap'
              }}
              onClick={toggleMobileMode}
            >
              <span style={{ marginRight: '3px', fontSize: '12px' }}>🖥️</span>
              <span className="button-text">Desktop</span>
            </button>
          </div>
        </div>
      </div>
      
      {/* Add CSS for responsive button text */}
      <style>
        {`
          @media (max-width: 768px) {
            .button-text {
              display: none;
            }
            .mobile-button {
              width: 30px !important;
              padding: 5px !important;
              min-width: 30px !important;
            }
            .driving-mode-text {
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          
          @media (min-width: 769px) and (max-width: 1024px) {
            .mobile-button {
              font-size: 10px !important;
              padding: 5px 8px !important;
            }
          }
          
          /* Add animation for pulse effect */
          @keyframes pulse {
            0% { box-shadow: 0 0 0 0 rgba(41, 117, 247, 0.4); }
            70% { box-shadow: 0 0 0 10px rgba(41, 117, 247, 0); }
            100% { box-shadow: 0 0 0 0 rgba(41, 117, 247, 0); }
          }
          
          /* Mobile-specific adjustments */
          @media (max-width: 480px) {
            /* Make buttons even smaller on phones */
            .mobile-button {
              width: 28px !important;
              height: 28px !important;
              min-width: 28px !important;
              border-radius: 14px !important;
            }
            
            /* Reduce gap between buttons */
            .mobile-button-container {
              gap: 5px !important;
            }
            
            /* Ensure driving mode indicator is visible but compact */
            .driving-mode-text {
              max-width: 50px;
            }
          }
        `}
      </style>
      
      {/* Vehicle search dropdown - position adjusted */}
      {showVehicleSearch && (
        <div style={{
          position: 'absolute',
          top: '70px', // Position adjusted
          left: '50%',
          transform: 'translateX(-50%)',
          width: '90%',
          maxWidth: '400px',
          zIndex: 1000,
          backgroundColor: darkMode ? 'rgba(30, 30, 30, 0.9)' : 'rgba(255, 255, 255, 0.9)',
          borderRadius: '8px',
          padding: '15px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.1)'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '12px'
          }}>
            <div style={{
              fontSize: '16px',
              fontWeight: 'bold',
              textAlign: 'center',
              color: darkMode ? 'white' : 'black',
              flex: 1
            }}>
              Select a vehicle to follow
            </div>
          </div>
          
          {vehicles.length === 0 ? (
            <div style={{
              textAlign: 'center',
              padding: '15px',
              color: darkMode ? '#ccc' : '#666',
              fontSize: '14px'
            }}>
              <p>Welcome to Mobile Map View</p>
              <p>Waiting for vehicle data to load...</p>
              <p>Once loaded, you can choose a vehicle to follow.</p>
            </div>
          ) : (
            <>
              <div className="map-vehicle-search-wrapper">
                <MapboxVehicleSearch 
                  vehicles={vehicles} 
                  onVehicleSelect={handleVehicleFollow}
                />
              </div>
              <div style={{
                fontSize: '12px',
                color: darkMode ? '#aaa' : '#666',
                marginTop: '10px',
                textAlign: 'center'
              }}>
                Select a vehicle to continue
              </div>
            </>
          )}
        </div>
      )}
      
      {/* Initial instruction message when no vehicle is selected */}
      {showVehicleSearch && !followedVehicle && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(41, 117, 247, 0.9)',
          color: 'white',
          padding: '15px 25px',
          borderRadius: '20px',
          fontWeight: 'bold',
          fontSize: '16px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
          zIndex: 901, // Above the frosted glass but below the vehicle search
          pointerEvents: 'none',
          textAlign: 'center',
          maxWidth: '80%'
        }}>
          <div style={{ fontSize: '28px', marginBottom: '10px' }}>👆</div>
          Select a vehicle above to start tracking
        </div>
      )}
      
      {/* Status bar in the middle - adjust its position to not overlap with top bar */}
      <StatusBar 
        followedVehicle={followedVehicle} 
        geocodedLocation={geocodedLocation}
        isGeocoding={isGeocoding}
      />
      
      {/* Marker naming modal */}
      <MarkerNameModal
        show={showNameModal}
        onClose={() => {
          setShowNameModal(false);
          setTempMarkerPosition(null);
        }}
        onSave={saveMarker}
      />
      
      {/* Visual indicator when in marking mode */}
      {isMarkingMode && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(220, 53, 69, 0.8)',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '20px',
          fontWeight: 'bold',
          zIndex: 1000,
          pointerEvents: 'none'
        }}>
          Tap on the map to place marker
        </div>
      )}
      
      {/* Add API Control component for admin users */}
      {user && user.role === 'admin' && (
        <ApiControl position="bottom-right" />
      )}
    </div>
  );
};

export default MapboxMobileMap; 