import { reverseGeocode } from '../../mobile/utils/geocodingUtils';
import { getSmartOverpassUrl, getOverpassUrl } from '../../../config/apiConfig';

/**
 * GeocodingService for Mapbox components
 * This service handles geocoding operations for the Mapbox mobile map
 * It encapsulates the logic for reverse geocoding (coordinates to address)
 */
class GeocodingService {
  constructor() {
    this.lastRequest = null;
    this.lastCoords = null;
    this.minDistanceChange = 10; // minimum distance in meters to trigger a new request
    this.debounceTime = 1000; // minimum time between requests in ms
    this.cachedResults = new Map(); // cache for geocoding results
    this.isGeocoding = false;
  }

  /**
   * Calculate distance between two points using Haversine formula
   * @param {number} lat1 - Latitude of point 1
   * @param {number} lon1 - Longitude of point 1
   * @param {number} lat2 - Latitude of point 2
   * @param {number} lon2 - Longitude of point 2
   * @returns {number} Distance in meters
   */
  haversineDistance(lat1, lon1, lat2, lon2) {
    const R = 6371e3; // Earth radius in meters
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  /**
   * Check if we should make a new request based on time and distance thresholds
   * @param {number} latitude - Latitude
   * @param {number} longitude - Longitude 
   * @returns {boolean} - Whether to make a new request
   */
  shouldMakeNewRequest(latitude, longitude) {
    console.log("shouldMakeNewRequest called with:", latitude, longitude);
    
    // For testing purposes, always return true
    return true;
    
    /* Commented out for testing
    // No previous request, definitely make a new one
    if (!this.lastRequest || !this.lastCoords) {
      return true;
    }
    
    // Calculate time since last request
    const timeSinceLastRequest = Date.now() - this.lastRequest;
    
    // If we've waited long enough, make a new request
    if (timeSinceLastRequest > this.debounceTime) {
      // Check distance from last coordinates
      if (this.lastCoords && this.lastCoords.latitude && this.lastCoords.longitude) {
        const distance = this.haversineDistance(
          this.lastCoords.latitude,
          this.lastCoords.longitude,
          latitude,
          longitude
        );
        
        // Convert to meters
        const distanceInMeters = distance * 1000;
        
        // If we've moved enough, make a new request
        return distanceInMeters > this.minDistanceChange;
      }
      
      return true;
    }
    
    return false;
    */
  }

  /**
   * Generate a cache key from coordinates
   * @param {number} latitude - Latitude
   * @param {number} longitude - Longitude
   * @returns {string} Cache key
   */
  getCacheKey(latitude, longitude) {
    // Round coordinates to reduce cache size and improve hit rate
    const precision = 5; // 5 decimal places is about 1 meter precision
    return `${latitude.toFixed(precision)},${longitude.toFixed(precision)}`;
  }

  /**
   * Extract street name from address components
   * @param {Object} address - OSM address components
   * @returns {string} Street name
   */
  extractStreetName(address) {
    if (!address) return 'Unknown street';
    
    // First try to get the road/street name with house number
    if (address.road) {
      const houseNumber = address.house_number ? `${address.house_number} ` : '';
      return `${houseNumber}${address.road}`;
    } 
    
    // Try alternative street types
    const streetTypes = ['street', 'highway', 'pedestrian', 'footway', 'path', 'cycleway', 'service'];
    for (const type of streetTypes) {
      if (address[type]) {
        return address.house_number ? `${address.house_number} ${address[type]}` : address[type];
      }
    }
    
    // If we have a place name, use that
    if (address.place) {
      return address.place;
    }
    
    // If we have a named feature or amenity, use that
    if (address.amenity) {
      return address.amenity;
    }
    
    // Fall back to available address parts
    if (address.suburb) {
      return address.suburb;
    }
    
    if (address.neighbourhood) {
      return address.neighbourhood;
    }
    
    return 'Unnamed road';
  }
  
  /**
   * Extract locality (city/town/village) from address components
   * @param {Object} address - OSM address components
   * @returns {string} Locality name
   */
  extractLocality(address) {
    if (!address) return '';
    
    // Try city/town/village in order of hierarchy
    if (address.city) {
      return address.city;
    }
    
    if (address.town) {
      return address.town;
    }
    
    if (address.village) {
      return address.village;
    }
    
    if (address.hamlet) {
      return address.hamlet;
    }
    
    if (address.suburb) {
      return address.suburb;
    }
    
    if (address.neighbourhood) {
      return address.neighbourhood;
    }
    
    // If we have county and state, combine them
    if (address.county && address.state) {
      return `${address.county}, ${address.state}`;
    }
    
    // If we have just county or state, use that
    if (address.county) {
      return address.county;
    }
    
    if (address.state) {
      return address.state;
    }
    
    return '';
  }

  /**
   * Get address information for a given location
   * @param {number} latitude - Latitude
   * @param {number} longitude - Longitude
   * @param {number} heading - Optional vehicle heading (no longer used for intersection lookup)
   * @returns {Promise<Object>} Geocoded location data
   */
  async getAddressForLocation(latitude, longitude, heading) {
    console.log(`GeocodingService.getAddressForLocation called with: lat=${latitude}, lon=${longitude}, heading=${heading}`);
    
    // Check if we should make a new request
    if (!this.shouldMakeNewRequest(latitude, longitude)) {
      console.log("Skipping geocoding request - using recent result");
      return null;
    }
    
    // Update last request time and coordinates
    this.lastRequest = Date.now();
    this.lastCoords = { latitude, longitude };
    
    // Check cache first
    const cacheKey = this.getCacheKey(latitude, longitude);
    if (this.cachedResults.has(cacheKey)) {
      console.log("Using cached geocoding result for:", cacheKey);
      return this.cachedResults.get(cacheKey);
    }
    
    // Set geocoding status
    this.isGeocoding = true;
    console.log("Starting geocoding request for:", latitude, longitude);
    
    try {
      // Create an AbortController for the request
      const controller = new AbortController();
      const signal = controller.signal;
      
      // Reduce timeout for faster recovery from failures (5 seconds instead of 10)
      const timeoutId = setTimeout(() => controller.abort(), 5000);
      
      // Use the simplified reverseGeocode function (heading parameter is ignored now)
      console.log("Calling reverseGeocode function...");
      const result = await reverseGeocode(latitude, longitude, signal);
      console.log("Received result from reverseGeocode:", result);
      
      // Clear the timeout
      clearTimeout(timeoutId);
      
      // If we have a result, process it
      if (result && result.address) {
        // Extract street name and locality
        const streetName = this.extractStreetName(result.address);
        const locality = this.extractLocality(result.address);
        
        // Create a formatted result with the relevant data
        const geocodedLocation = {
          // Legacy address format for compatibility
          address: result.display_name || result.displayName || this.formatAddress(result.address),
          displayName: result.display_name,
          
          // New address components for better display
          street: streetName,
          locality: locality,
          
          // Original address components for reference
          addressComponents: result.address,
          
          // Add raw data for debugging
          lat: latitude,
          lon: longitude,
          
          // Note if this is from a fallback source
          isFallback: result.isFallback || false
        };
        
        // Cache the result
        this.cachedResults.set(cacheKey, geocodedLocation);
        
        // Set geocoding status
        this.isGeocoding = false;
        
        console.log("Geocoding successful:", streetName);
        return geocodedLocation;
      } else {
        // If result is empty or missing address, try to use coordinates as fallback
        console.warn("Received empty result from geocoding service");
        
        // Create a basic fallback
        const fallbackLocation = {
          address: `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`,
          displayName: `Location at ${latitude.toFixed(5)}, ${longitude.toFixed(5)}`,
          street: `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`,
          locality: '',
          addressComponents: null,
          lat: latitude,
          lon: longitude,
          isFallback: true
        };
        
        // Cache the fallback result 
        this.cachedResults.set(cacheKey, fallbackLocation);
        
        // Set geocoding status
        this.isGeocoding = false;
        
        console.log("Using coordinates as fallback for location");
        return fallbackLocation;
      }
    } catch (error) {
      // Handle specific error types differently
      console.error('Error in reverse geocoding:', error);
      
      // Create a basic fallback
      const fallbackLocation = {
        address: `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`,
        displayName: `Location at ${latitude.toFixed(5)}, ${longitude.toFixed(5)}`,
        street: `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`,
        locality: '',
        addressComponents: null,
        lat: latitude,
        lon: longitude,
        isFallback: true
      };
      
      // Cache the fallback result
      this.cachedResults.set(cacheKey, fallbackLocation);
      
      // Set geocoding status
      this.isGeocoding = false;
      
      return fallbackLocation;
    }
  }

  /**
   * Format address from OSM address components
   * @param {Object} addressComponents - OSM address components
   * @returns {string} Formatted address
   */
  formatAddress(addressComponents) {
    if (!addressComponents) return 'Unknown location';
    
    const components = [];
    
    // Add road/street name with house number if available
    if (addressComponents.road) {
      const houseNumber = addressComponents.house_number ? `${addressComponents.house_number} ` : '';
      components.push(`${houseNumber}${addressComponents.road}`);
    } else if (addressComponents.pedestrian) {
      components.push(addressComponents.pedestrian);
    } else if (addressComponents.footway) {
      components.push(addressComponents.footway);
    } else if (addressComponents.path) {
      components.push(addressComponents.path);
    }
    
    // Add suburb/neighborhood if available
    if (addressComponents.suburb) {
      components.push(addressComponents.suburb);
    } else if (addressComponents.neighbourhood) {
      components.push(addressComponents.neighbourhood);
    }
    
    // Add city/town/village
    if (addressComponents.city) {
      components.push(addressComponents.city);
    } else if (addressComponents.town) {
      components.push(addressComponents.town);
    } else if (addressComponents.village) {
      components.push(addressComponents.village);
    }
    
    // Add state/province and postal code
    if (addressComponents.state) {
      const postalCode = addressComponents.postcode ? ` ${addressComponents.postcode}` : '';
      components.push(`${addressComponents.state}${postalCode}`);
    }
    
    return components.join(', ');
  }

  /**
   * Get the current geocoding status
   * @returns {boolean} Whether geocoding is in progress
   */
  getGeocodingStatus() {
    return this.isGeocoding;
  }
}

// Create and export a singleton instance
const geocodingService = new GeocodingService();
export default geocodingService; 