// Import the API configuration
import { getOverpassUrl, getSmartOverpassUrl } from '../../../config/apiConfig';

// Add API configuration at the top of the file
const API_CONFIG = {
  nominatim: {
    // Internet-accessible endpoint
    url: 'https://nominatim.westchesterrtc.com',
    // API key for authentication
    apiKey: '0b760c5caff24c6c7daa3e5400cc0530',
    // Public fallback in case our endpoint is down
    fallback: 'https://nominatim.openstreetmap.org'
  }
};

// Create a rate limiting mechanism for API calls
const apiRateLimiter = {
  overpassLastCall: 0,
  nominatimLastCall: 0,
  minDelayBetweenCalls: 1000, // 1 second between calls
  overpassDelay: 2000, // 2 seconds between Overpass calls (more strict)

  canCallOverpass() {
    const now = Date.now();
    return now - this.overpassLastCall >= this.overpassDelay;
  },

  canCallNominatim() {
    const now = Date.now();
    return now - this.nominatimLastCall >= this.minDelayBetweenCalls;
  },

  registerOverpassCall() {
    this.overpassLastCall = Date.now();
  },

  registerNominatimCall() {
    this.nominatimLastCall = Date.now();
  },

  async waitForOverpassSlot(abortSignal) {
    const now = Date.now();
    const waitTime = Math.max(0, this.overpassDelay - (now - this.overpassLastCall));
    
    if (waitTime > 0) {
      console.log(`Rate limiting: waiting ${waitTime}ms before Overpass API call`);
      await new Promise((resolve, reject) => {
        const timeoutId = setTimeout(resolve, waitTime);
        if (abortSignal) {
          abortSignal.addEventListener('abort', () => {
            clearTimeout(timeoutId);
            reject(new DOMException('Aborted', 'AbortError'));
          });
        }
      });
    }
    
    this.registerOverpassCall();
  },

  async waitForNominatimSlot(abortSignal) {
    const now = Date.now();
    const waitTime = Math.max(0, this.minDelayBetweenCalls - (now - this.nominatimLastCall));
    
    if (waitTime > 0) {
      console.log(`Rate limiting: waiting ${waitTime}ms before Nominatim API call`);
      await new Promise((resolve, reject) => {
        const timeoutId = setTimeout(resolve, waitTime);
        if (abortSignal) {
          abortSignal.addEventListener('abort', () => {
            clearTimeout(timeoutId);
            reject(new DOMException('Aborted', 'AbortError'));
          });
        }
      });
    }
    
    this.registerNominatimCall();
  }
};

// Retry logic for API calls with exponential backoff
const withRetry = async (fn, maxRetries = 3, initialDelay = 1000) => {
  let retries = 0;
  let delay = initialDelay;
  
  while (true) {
    try {
      return await fn();
    } catch (error) {
      if (error.name === 'AbortError') {
        // Don't retry if the request was intentionally aborted
        throw error;
      }
      
      retries += 1;
      if (retries > maxRetries) {
        throw error;
      }
      
      console.log(`Retry ${retries}/${maxRetries} after ${delay}ms`);
      await new Promise(resolve => setTimeout(resolve, delay));
      
      // Exponential backoff with some randomness
      delay = delay * 1.5 + Math.random() * 1000;
    }
  }
};

// Convert meters to imperial measurements (feet or miles)
const metersToImperial = (meters) => {
    if (meters === undefined || meters === null) return '';
    
    const feet = meters * 3.28084;
    
    if (feet < 1000) {
        // Less than 1000 feet, show in feet
        return `${Math.round(feet)}ft`;
    } else {
        // More than 1000 feet, show in miles with one decimal
        const miles = feet / 5280;
        return `${miles.toFixed(1)}mi`;
    }
};

// Ensure heading is always a valid number to avoid issues with Overpass API
export const sanitizeHeading = (heading) => {
    if (heading === undefined || heading === null || isNaN(heading)) {
        return 0;
    }
    
    // Normalize heading to the range 0-359
    return (Math.round(heading) % 360 + 360) % 360;
};

// Get the next intersection ahead using Overpass API
const getNextIntersectionFromOverpass = async (lat, lon, heading, radius = 250, abortSignal) => {
  try {
    // Wait for rate limiter slot
    await apiRateLimiter.waitForOverpassSlot(abortSignal);
    
    // Instead of creating the bounding box from current position + heading,
    // use a simple radius approach to get all nearby features first
    const boundingBoxRadius = radius / 111000; // Convert meters to rough degrees
    
    // Create a bounding box
    const boundingBox = {
      minLat: lat - boundingBoxRadius,
      minLon: lon - boundingBoxRadius,
      maxLat: lat + boundingBoxRadius,
      maxLon: lon + boundingBoxRadius
    };
    
    // Build the Overpass query - completely rewrote with proper syntax for the arrow operators
    const overpassQuery = `
[out:json];
(
  // Highway exits and junctions with detailed data
  node["highway"="motorway_junction"](${boundingBox.minLat},${boundingBox.minLon},${boundingBox.maxLat},${boundingBox.maxLon});
  
  // Specifically capture exits with ref numbers (exit numbers)
  node["highway"="motorway_junction"]["ref"](${boundingBox.minLat},${boundingBox.minLon},${boundingBox.maxLat},${boundingBox.maxLon});
  
  // Also get exit destinations (to where the exit leads)
  node["highway"="motorway_junction"]["exit_to"](${boundingBox.minLat},${boundingBox.minLon},${boundingBox.maxLat},${boundingBox.maxLon});
  
  // All road intersections (more comprehensive than just traffic signals)
  way["highway"](${boundingBox.minLat},${boundingBox.minLon},${boundingBox.maxLat},${boundingBox.maxLon});
  node(w)->.highway_nodes;
  
  // Find actual intersections (where multiple ways meet)
  .highway_nodes->.potential_intersections;
  way["highway"]["name"](${boundingBox.minLat},${boundingBox.minLon},${boundingBox.maxLat},${boundingBox.maxLon})->.named_roads;
  node.potential_intersections->.potential_intersections_filtered;
  .potential_intersections_filtered>.named_roads->.real_nodes;
  
  // Include stop signs and traffic signals as they often mark intersections
  node["highway"="stop"](${boundingBox.minLat},${boundingBox.minLon},${boundingBox.maxLat},${boundingBox.maxLon});
  node["highway"="traffic_signals"](${boundingBox.minLat},${boundingBox.minLon},${boundingBox.maxLat},${boundingBox.maxLon});
  
  // Capture on/off ramps which often indicate upcoming exits
  way["highway"="motorway_link"](${boundingBox.minLat},${boundingBox.minLon},${boundingBox.maxLat},${boundingBox.maxLon});
  
  // Get named nodes and points of interest
  node["name"](${boundingBox.minLat},${boundingBox.minLon},${boundingBox.maxLat},${boundingBox.maxLon});
);
out body;
way(bn);
out tags;`;
    
    // Get proper Overpass API URL - resolve Promise before using
    let apiUrl;
    try {
      apiUrl = await getSmartOverpassUrl();
      console.log(`Using Overpass API endpoint: ${apiUrl}`);
    } catch (error) {
      console.error("Error getting smart Overpass URL, falling back to default:", error);
      apiUrl = getOverpassUrl(); // Fallback to synchronous version
    }
    
    // Wrap the API call in retry logic
    return withRetry(async () => {
      try {
        // Check if the abort signal is already aborted
        if (abortSignal && abortSignal.aborted) {
          throw new DOMException('Aborted', 'AbortError');
        }
        
        console.log(`Querying Overpass API for intersections near ${lat}, ${lon} with heading ${heading}`);
        console.log(`Overpass query: ${overpassQuery.trim()}`);
        
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          },
          body: `data=${encodeURIComponent(overpassQuery)}`,
          signal: abortSignal
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error(`Overpass API error (${response.status}):`, errorText);
          
          // Special handling for rate limiting
          if (response.status === 429) {
            console.log("Rate limited by Overpass API, increasing delay");
            apiRateLimiter.overpassDelay = Math.min(apiRateLimiter.overpassDelay * 2, 30000); // Up to 30 seconds
            throw new Error('Rate limited by Overpass API');
          }
          
          throw new Error(`Overpass API returned ${response.status}: ${errorText}`);
        }
        
        const data = await response.json();
        
        // If request succeeded, gradually reduce the delay back to normal
        if (apiRateLimiter.overpassDelay > 2000) {
          apiRateLimiter.overpassDelay = Math.max(2000, apiRateLimiter.overpassDelay * 0.8);
        }
        
        return data;
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Overpass API request was aborted');
          throw error;
        }
        
        console.error('Error fetching from Overpass API:', error);
        throw error;
      }
    });
  } catch (error) {
    console.error('Error in getNextIntersectionFromOverpass:', error);
    throw error;
  }
};

// Reverse geocode a coordinate to an address
export const reverseGeocode = async (latitude, longitude, abortSignal) => {
  // Generate a unique request ID for logging
  const requestId = Math.random().toString(36).substring(2, 8);
  console.log(`[${requestId}] Starting reverse geocoding for ${latitude}, ${longitude}`);
  
  try {
    // Wait for rate limiter slot
    await apiRateLimiter.waitForNominatimSlot(abortSignal);
    
    // Create options for the fetch request
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      },
      signal: abortSignal
    };
    
    // Try our primary API first
    let response = null;
    let apiUrl = '';
    let usedUrl = '';
    let usedFallback = false;
    
    try {
      // Add API key to headers if available
      if (API_CONFIG.nominatim.apiKey) {
        options.headers['X-API-Key'] = API_CONFIG.nominatim.apiKey;
      }
      
      apiUrl = `${API_CONFIG.nominatim.url}/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
      console.log(`[${requestId}] Using primary Nominatim API: ${API_CONFIG.nominatim.url}`);
      
      const timeout = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Nominatim API timeout')), 3000)
      );
      
      response = await Promise.race([
        fetch(apiUrl, options),
        timeout
      ]);
      
      usedUrl = API_CONFIG.nominatim.url;
    } catch (error) {
      console.log(`[${requestId}] Primary Nominatim API failed:`, error.message);
      
      // If primary API fails, try the public OpenStreetMap API
      console.log(`[${requestId}] Trying public OpenStreetMap API fallback`);
      
      // Public OSM API doesn't need an API key
      if (options.headers['X-API-Key']) {
        delete options.headers['X-API-Key'];
      }
      
      apiUrl = `${API_CONFIG.nominatim.fallback}/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
      
      // Must respect OSM's usage policy: at most 1 request per second
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      response = await fetch(apiUrl, options);
      usedUrl = API_CONFIG.nominatim.fallback;
      usedFallback = true;
    }
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error(`[${requestId}] Nominatim API error (${response.status}) from ${usedUrl}:`, errorText);
      throw new Error(`Nominatim API returned ${response.status}: ${errorText}`);
    }
    
    const data = await response.json();
    console.log(`[${requestId}] Successfully received data from ${usedFallback ? 'fallback' : 'primary'} Nominatim API`);
    
    return {
      ...data,
      addressComponents: data.address,
      isFallback: usedFallback
    };
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log(`[${requestId}] Geocoding request was aborted`);
      throw error;
    }
    
    console.error(`[${requestId}] Error in reverse geocoding:`, error);
    throw error;
  }
};

// Process the Overpass data to extract relevant information
const processOverpassData = (data, lat, lon, heading) => {
  if (!data || !data.elements || data.elements.length === 0) {
    console.log("No elements found in Overpass data");
    return null;
  }
  
  try {
    console.log(`Processing Overpass data with ${data.elements.length} elements`);
    
    // Filter to only node elements and calculate distance for each
    const nodes = data.elements
      .filter(element => element.type === 'node')
      .map(node => {
        // Calculate distance from the original point in meters
        const distance = calculateDistance(
          lat, lon, 
          node.lat, node.lon
        ) * 1000; // Convert km to meters
        
        // Calculate angle and heading difference to determine if it's ahead
        const angle = calculateBearing(lat, lon, node.lat, node.lon);
        const headingDifference = calculateHeadingDifference(heading, angle);
        
        // Consider a node ahead if it's within 90 degrees of our heading
        const isAhead = headingDifference <= 90;
        
        return {
          ...node,
          distance,
          formattedDistance: metersToImperial(distance),
          angle,
          headingDifference,
          isAhead
        };
      })
      // Sort by distance
      .sort((a, b) => a.distance - b.distance);
    
    console.log(`Found ${nodes.length} nodes after filtering and sorting`);
    
    // Extract intersections (traffic lights, stop signs, or named intersections)
    const intersections = nodes.filter(node => {
      // Check if it's a traffic light or stop sign
      if (node.tags && (
          node.tags.highway === 'traffic_signals' || 
          node.tags.highway === 'stop' ||
          node.tags.highway === 'crossing'
        )) {
        return true;
      }
      
      // Check if it has a name (might be a named intersection)
      if (node.tags && node.tags.name) {
        return true;
      }
      
      return false;
    });
    
    // Extract highway exits (motorway junctions)
    const exits = nodes.filter(node => 
      node.tags && node.tags.highway === 'motorway_junction'
    );
    
    console.log(`Found ${intersections.length} intersections and ${exits.length} exits`);
    
    // Find nearest intersection ahead (in the direction we're traveling)
    const nextIntersection = intersections
      .filter(node => node.isAhead)
      .sort((a, b) => a.distance - b.distance)[0];
    
    // Find nearest exit ahead
    const nearestExit = exits
      .filter(node => node.isAhead)
      .sort((a, b) => a.distance - b.distance)[0];
    
    // Find closest intersection regardless of direction
    const nearestIntersection = intersections
      .sort((a, b) => a.distance - b.distance)[0];
    
    // Build the result
    const result = {};
    
    // Format the next intersection information
    if (nextIntersection) {
      const name = nextIntersection.tags.name || 
                   (nextIntersection.tags.highway === 'traffic_signals' ? 'Traffic Light' : 
                   (nextIntersection.tags.highway === 'stop' ? 'Stop Sign' : 'Intersection'));
      result.nextIntersection = `${name} (${nextIntersection.formattedDistance} ahead)`;
    }
    
    // Format the nearest exit information
    if (nearestExit) {
      const exitRef = nearestExit.tags.ref ? `Exit ${nearestExit.tags.ref}` : 'Exit';
      const exitTo = nearestExit.tags.exit_to ? ` to ${nearestExit.tags.exit_to}` : '';
      result.nearestExit = `${exitRef}${exitTo} (${nearestExit.formattedDistance} ahead)`;
    }
    
    // Format the nearest intersection information if we don't have one ahead
    if (nearestIntersection && !nextIntersection) {
      const name = nearestIntersection.tags.name || 
                   (nearestIntersection.tags.highway === 'traffic_signals' ? 'Traffic Light' : 
                   (nearestIntersection.tags.highway === 'stop' ? 'Stop Sign' : 'Intersection'));
      const direction = nearestIntersection.isAhead ? 'ahead' : 'nearby';
      result.nearestIntersection = `${name} (${nearestIntersection.formattedDistance} ${direction})`;
    }
    
    console.log('Processed intersection data:', result);
    
    return result;
  } catch (error) {
    console.error('Error processing Overpass data:', error);
    return null;
  }
};

// Function to fetch a vehicle's location
export const fetchVehicleLocation = async (displayName) => {
    if (!displayName) return null;
    
    try {
        // Fetch the latest locations from the endpoint
        const response = await fetch('/api/update-locations', {
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Fetched location data:', data);
        
        // Find the vehicle with the matching displayName
        const vehicle = data.find(v => v.displayName === displayName);
        
        if (vehicle) {
            console.log('Found vehicle data:', vehicle);
            return {
                displayName: vehicle.displayName,
                latitude: vehicle.latitude,
                longitude: vehicle.longitude,
                heading: vehicle.heading || 0,
                // Add any other properties needed for reverse geocoding
                address: null // Will be filled by reverse geocoding
            };
        } else {
            console.warn(`Vehicle with displayName "${displayName}" not found in location data`);
            return null;
        }
    } catch (error) {
        console.error('Error fetching vehicle location:', error);
        return null;
    }
};

// Calculate the distance between two points in kilometers
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c; // Distance in km
};

// Calculate the bearing/heading from point 1 to point 2
const calculateBearing = (lat1, lon1, lat2, lon2) => {
  // Convert to radians
  const lat1Rad = lat1 * Math.PI / 180;
  const lat2Rad = lat2 * Math.PI / 180;
  const lonDiff = (lon2 - lon1) * Math.PI / 180;

  const y = Math.sin(lonDiff) * Math.cos(lat2Rad);
  const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) -
          Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(lonDiff);
  
  let bearing = Math.atan2(y, x) * 180 / Math.PI;
  if (bearing < 0) {
    bearing += 360;
  }
  
  return bearing;
};

// Calculate the difference between two headings (0-359 degrees)
const calculateHeadingDifference = (heading1, heading2) => {
  // Normalize inputs to 0-359 range
  heading1 = ((heading1 % 360) + 360) % 360;
  heading2 = ((heading2 % 360) + 360) % 360;
  
  // Calculate absolute difference
  let diff = Math.abs(heading1 - heading2);
  
  // Take the smaller angle (never more than 180 degrees)
  if (diff > 180) {
    diff = 360 - diff;
  }
  
  return diff;
};

// Export the functions
export {
  getNextIntersectionFromOverpass,
  apiRateLimiter, // Export for testing and monitoring
  processOverpassData,
  calculateDistance,
  calculateBearing,
  calculateHeadingDifference,
  metersToImperial
}; 